@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .btn-signout {
    @apply px-4 py-2 font-bold text-red-500 border border-red-500 rounded hover:text-red-700;
  }

  .btn-cancel {
    @apply px-2 py-1 text-red-500 border border-red-500 rounded hover:text-red-700;
  }

  .btn-delete {
    @apply px-2 py-1 text-red-500 bg-white border border-red-500 rounded hover:text-red-700 disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none;
  }

  .btn-add {
    @apply px-4 py-2 font-bold border rounded text-brand border-brand hover:text-brand-dark;
  }

  .card-hover {
    @apply absolute inset-0 z-10 p-3 overflow-visible scale-125 bg-white bg-cover border rounded-md h-fit drop-shadow-lg;
  }

  .table-header {
    @apply p-3 font-medium text-left text-gray-500 font-tableHeader;
  }

  .dropdown-show {
    @apply absolute top-0 right-0 z-10 w-40 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none;
  }

  .dropdown-option {
    @apply block px-4 py-2 text-sm text-left text-gray-700;
  }

  .btn-dropdown {
    @apply inline-flex items-center justify-center w-full font-medium text-gray-700 bg-white rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500;
  }

  .alert a {
    text-decoration: underline;
  }
  .bg-repeating-linear-gradient {
    background-image: repeating-linear-gradient(
      315deg,
      #f7f7f7 15px,
      #fff 40px
    );
  }
}
