@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800;900&display=swap);

:root {
  --ion-toolbar-background: white;
  --ion-font-family: "Poppins", sans-serif;
  --ion-color-primary: #E25D62;
  --ion-color-primary-rgb: 226,93,98;
	--ion-color-primary-contrast: white;
	--ion-color-primary-contrast-rgb: 0,0,0;
	--ion-color-primary-shade: #c75256;
	--ion-color-primary-tint: #e56d72;

  --ion-color-secondary: #DDDDDD;
	--ion-color-secondary-rgb: 221,221,221;
	--ion-color-secondary-contrast: #343434;
	--ion-color-secondary-contrast-rgb: 52,52,52;
	--ion-color-secondary-shade: #c2c2c2;
	--ion-color-secondary-tint: #e0e0e0;

  --ion-activated: #E25D62;

  --ion-toolbar-border-color: transparent;
}