.ql-bubble .ql-tooltip-editor input[type="text"] {
  position: relative;
  overflow-y: visible;
}
.ql-container.ql-bubble {
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-family: 'Poppins', sans-serif;
}
.ql-editor {
  padding: 0.5rem 0.75rem !important;
}